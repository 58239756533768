var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "login-form" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: _vm.logo, alt: "BeWellPlan Dashboard" },
      }),
      !_vm.$auth.loading
        ? _c(
            "div",
            [
              !_vm.$auth.isAuthenticated
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.login },
                    },
                    [_vm._v("Login")]
                  )
                : _vm._e(),
              _vm.$auth.isAuthenticated
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Logout")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }